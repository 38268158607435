.mainDiv {
    display: flex;
    height: 100%;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #88FFD7;
}

.logoDiv {
    display: inline-flexbox;
}

.viyana {
    color: yellow;
    text-align: center;
    font-size: 60px;
}

.moto {
    font-size: 35px;
    text-align: center;
    margin-top: 2vh;
    margin-bottom: 4vh;
    color: rgb(255, 0, 221);
    -webkit-text-stroke: 1px #000;
    font-family: 'Lobster', cursive;
}

.linkswrapper {
    display: flex;
    justify-content: space-between;
    /* align-self: flex-start; */
}

@media only screen and (max-width: 750px) {

    /* For mobile phones: */
    .logoText {
        background-color: tomato;
    }

    .moto {
        font-size: 5.2vw;
        margin-left: 10px;
        margin-right: 5px;
    }

    .linkswrapper {
        display: block;
        /* width: 100%; */
    }
}

@media only screen and (max-width: 450px) {

    /* For mobile phones: */
    .moto {
        -webkit-text-stroke: 0.5px #000;
    }
}

@media only screen and (max-width: 850px) {
    .headerWrapper {
        align-items: normal !important;
        background-color: red;
        display: none !important;
    }

    .headerLinksWrapper {
        flex-direction: column;
        align-items: normal !important;
        /* padding-top: 10%; */
        position: relative;
        top: 15px;
    }
    .headerWrapperForMobileHideShow{
        display: block !important;
    }

    .headerWrapperForMobile {
        display: flex !important;
        justify-content: space-between;
        align-items: baseline;
        background-color: #88FFD7;
    }
}
.headerWrapperForMobileHideShow{
    display: none;
}
.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: #88FFD7;
    padding-left: 20px;
}

.headerLinksWrapper {
    display: flex;
    align-items: baseline;
    
}
.componentWrapper{
    background-color: #88FFD7;
    margin-bottom: 10px;
    padding-bottom: 15px;
}
.headerWrapperForMobile {
    display: none;
}