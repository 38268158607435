.links {
    color: #000;
    font-size: 20px;
    /* background: #6fb1ff; */
    background-color: #88FFD7;
    margin-right: 5px;
    margin-left: 5px;
    /* margin-top: 50px; */
    font-family: 'Lobster', cursive;
    /* -webkit-text-stroke: 0.6px #000; */
}
.linkWrapper{
    text-decoration: none;
    background-color: #88FFD7;
    border: 2px solid #00648D;
    border-radius: 15px;
    text-align: center;
    padding: 5px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 50px;
}
.linksSecondView {
    color: #000;
    font-size: 20px;
    /* background: #6fb1ff; */
    background-color: #88FFD7;
    margin-right: 5px;
    margin-left: 5px;
    /* margin-top: 50px; */
    font-family: 'Lobster', cursive;
    /* -webkit-text-stroke: 0.6px #000; */
}
.linkWrapperSecondView{
    text-decoration: none;
    background-color: #88FFD7;
    border: 2px solid #00648D;
    border-radius: 15px;
    text-align: center;
    padding: 5px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 25px;
}
@media only screen and (max-width: 750px) {
    /* For mobile phones: */
    .links{
        font-size: 4vw;
    }
    .linkWrapper{
        display: block;
        width: 90vw;
        margin-bottom: 15px;
    }
  }