.mainContactsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #88FFD7;
    border: 2px solid #00648D;
    border-radius: 15px;
    padding: 2.5% 10%;
    margin: 8%;
}

.contactWrapper {
    display: flex;
    align-items: center;
}

.map {
    border: 0;
    width: 100%;
    height: 460px
}

.pic {
    width: 50px;
    height: 50px;
    margin: 5% 0;
}