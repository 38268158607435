@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
*{
    margin: 0%;
}
.box_mainDiv__1SSIU{
    padding-top: 10px;
    text-align: center;
    background-color: #fff;
    width: 100%;
    /* color: #fff; */
    font-family: 'Lobster', cursive;
}
.box_textHolder__1Sa1D{
    padding-top: 15px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 2.5%;
}
.box_outsideBoxText__p_LsJ{
    margin: 20px 1vw;
    font-size: 150%;
}
.box_insideBoxText__25WJ_{
    font-size: 13pt;
    width: 45%;
    padding: 1.5%;
    background-color: #88FFD7;
    /* border: 2px solid #00801F; */
    border: 2px solid #00648D;
    border-radius: 15px;
    text-align: center;
}
@media only screen and (max-width: 450px) {
    .box_textHolder__1Sa1D{
        display: block;
        width: 100%;
    }
    .box_insideBoxText__25WJ_{
        font-style: 12pt;
        padding: 1% 0.1%;
        width: 100%;
        margin-bottom: 5%;
        margin-left: 1%;
        margin-right: 1%;
    }
    .box_brainCellsImg__MV1jS{
        width: 75%;
    }
}

.logo_logoWrapper__2rsN3{
    display: flex;
    justify-content: center;
}
.logo_logoWrapper__2rsN3 img{
    /* width: 150px;
    height: 150px; */
}
.logo_logoText___gsIu{
    font-family: 'Lobster', cursive;
    /* text-align: center; */
}
.logo_Span0__1dgnS{
    color: #00D039;
    -webkit-text-stroke: 3px #FCBB01;
    text-shadow: 3px 3px 3px red;
}
.logo_Span1__15SjW{
    color: #FB1919;
    -webkit-text-stroke: 3px #af02d6;
    text-shadow: 3px 3px 3px rgb(245, 200, 0);
}
.logo_Span2__CB7YK{
    color: #FF68AF;
    -webkit-text-stroke: 1px #fff;
    text-shadow: 3px 3px 3px red;
}
.logo_Span3__1WKqV{
    color: #FDEA30;
    -webkit-text-stroke: 3px #05962A;
    text-shadow: 3px 3px 3px red;
}
.logo_Span4__2Ukzg{
    color: #DE30FD;
    -webkit-text-stroke: 3px #FF0000;
    text-shadow: 3px 3px 3px greenyellow;
}
@media only screen and (max-width: 750px) {
    /* For mobile phones: */
    .logo_logoText___gsIu{
        font-size: 12vw;
    }
    .logo_Span0__1dgnS{
        -webkit-text-stroke: 1px #FCBB01;
    }
    .logo_Span1__15SjW{
        -webkit-text-stroke: 1px #af02d6;
    }
    .logo_Span2__CB7YK{
        -webkit-text-stroke: 1px #fff;
    }
    .logo_Span3__1WKqV{
        -webkit-text-stroke: 1px #05962A;
    }
    .logo_Span4__2Ukzg{
        -webkit-text-stroke: 1px #FF0000;
    }
}
footer{
    background-color: cadetblue;
    height: 100%;
    /* width: 100%; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding-left: 1%;
    padding-top: 2.5%;
    padding-bottom: 1.30%; */
}
footer ul li{
    list-style: none;
    margin: 0;
    padding: 0;
}
footer a {
    text-decoration: none;
}
.footer_noTextDec__3VhTg{
    text-decoration: none;
}
.Header_mainDiv__23jL2 {
    display: flex;
    height: 100%;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #88FFD7;
}

.Header_logoDiv__1Ya97 {
    display: inline-flexbox;
}

.Header_viyana__1iGz4 {
    color: yellow;
    text-align: center;
    font-size: 60px;
}

.Header_moto__2tmjE {
    font-size: 35px;
    text-align: center;
    margin-top: 2vh;
    margin-bottom: 4vh;
    color: rgb(255, 0, 221);
    -webkit-text-stroke: 1px #000;
    font-family: 'Lobster', cursive;
}

.Header_linkswrapper__1Wrin {
    display: flex;
    justify-content: space-between;
    /* align-self: flex-start; */
}

@media only screen and (max-width: 750px) {

    /* For mobile phones: */
    .Header_logoText__1ZOPQ {
        background-color: tomato;
    }

    .Header_moto__2tmjE {
        font-size: 5.2vw;
        margin-left: 10px;
        margin-right: 5px;
    }

    .Header_linkswrapper__1Wrin {
        display: block;
        /* width: 100%; */
    }
}

@media only screen and (max-width: 450px) {

    /* For mobile phones: */
    .Header_moto__2tmjE {
        -webkit-text-stroke: 0.5px #000;
    }
}

@media only screen and (max-width: 850px) {
    .Header_headerWrapper__1a8R1 {
        align-items: normal !important;
        background-color: red;
        display: none !important;
    }

    .Header_headerLinksWrapper__wC5qJ {
        flex-direction: column;
        align-items: normal !important;
        /* padding-top: 10%; */
        position: relative;
        top: 15px;
    }
    .Header_headerWrapperForMobileHideShow__2s0lz{
        display: block !important;
    }

    .Header_headerWrapperForMobile__11e3D {
        display: flex !important;
        justify-content: space-between;
        align-items: baseline;
        background-color: #88FFD7;
    }
}
.Header_headerWrapperForMobileHideShow__2s0lz{
    display: none;
}
.Header_headerWrapper__1a8R1 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: #88FFD7;
    padding-left: 20px;
}

.Header_headerLinksWrapper__wC5qJ {
    display: flex;
    align-items: baseline;
    
}
.Header_componentWrapper__2MDu7{
    background-color: #88FFD7;
    margin-bottom: 10px;
    padding-bottom: 15px;
}
.Header_headerWrapperForMobile__11e3D {
    display: none;
}
.linksstyle_links__30Eed {
    color: #000;
    font-size: 20px;
    /* background: #6fb1ff; */
    background-color: #88FFD7;
    margin-right: 5px;
    margin-left: 5px;
    /* margin-top: 50px; */
    font-family: 'Lobster', cursive;
    /* -webkit-text-stroke: 0.6px #000; */
}
.linksstyle_linkWrapper__37mvl{
    text-decoration: none;
    background-color: #88FFD7;
    border: 2px solid #00648D;
    border-radius: 15px;
    text-align: center;
    padding: 5px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 50px;
}
.linksstyle_linksSecondView__1uZGN {
    color: #000;
    font-size: 20px;
    /* background: #6fb1ff; */
    background-color: #88FFD7;
    margin-right: 5px;
    margin-left: 5px;
    /* margin-top: 50px; */
    font-family: 'Lobster', cursive;
    /* -webkit-text-stroke: 0.6px #000; */
}
.linksstyle_linkWrapperSecondView__3VytK{
    text-decoration: none;
    background-color: #88FFD7;
    border: 2px solid #00648D;
    border-radius: 15px;
    text-align: center;
    padding: 5px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 25px;
}
@media only screen and (max-width: 750px) {
    /* For mobile phones: */
    .linksstyle_links__30Eed{
        font-size: 4vw;
    }
    .linksstyle_linkWrapper__37mvl{
        display: block;
        width: 90vw;
        margin-bottom: 15px;
    }
  }
.Burger_hamburgerIcon__OnYjU{
    cursor: pointer;
    display: none;
}
@media only screen and (max-width: 850px) {
    .Burger_hamburgerIcon__OnYjU{
        display: block !important;
        padding-top: 25px;
        width: 35px;
    }
}
.posts_postDiv__jHcZf{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.posts_post__2Wurq{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #88FFD7;
    border: 2px solid #00648D;
    border-radius: 15px;
    margin: 2% 0;
    padding: 1% 10% 2% 10%;
    max-width: 50%;
    min-width: 50%;
    font-family: 'Lobster', cursive;
    cursor: pointer;
}
.posts_post__2Wurq h1{
    margin-bottom: 1%;
}
.post_post__3rH3c {
    margin: 2% 0 25% 0;
}
.post_title__3Dw7M{
    text-align: center;
    margin-bottom: 20px;
}
.contactUs_mainContactsWrapper__XtaLd {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #88FFD7;
    border: 2px solid #00648D;
    border-radius: 15px;
    padding: 2.5% 10%;
    margin: 8%;
}

.contactUs_contactWrapper__2wGES {
    display: flex;
    align-items: center;
}

.contactUs_map__1xsBj {
    border: 0;
    width: 100%;
    height: 460px
}

.contactUs_pic__3TSVc {
    width: 50px;
    height: 50px;
    margin: 5% 0;
}
