.postDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.post{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #88FFD7;
    border: 2px solid #00648D;
    border-radius: 15px;
    margin: 2% 0;
    padding: 1% 10% 2% 10%;
    max-width: 50%;
    min-width: 50%;
    font-family: 'Lobster', cursive;
    cursor: pointer;
}
.post h1{
    margin-bottom: 1%;
}