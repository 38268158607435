footer{
    background-color: cadetblue;
    height: 100%;
    /* width: 100%; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding-left: 1%;
    padding-top: 2.5%;
    padding-bottom: 1.30%; */
}
footer ul li{
    list-style: none;
    margin: 0;
    padding: 0;
}
footer a {
    text-decoration: none;
}
.noTextDec{
    text-decoration: none;
}